import { Col, Row, Modal, Form, Select, notification } from "antd";
import Field from "@components/shared/Fields";
import React, { Fragment, useState } from "react";
import { normalizePhoneNumber, phoneValidator } from "~/src/libs/utils/phoneValidator";
import CustomButton from "../Button";
import style from "../HaggleModal/styles.module.scss";
import { modalProps } from "./types";
import { getText } from "~/src/libs/resources";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import ThankYouModal from "../HaggleModal/ThankYouModal";
const RequestQuoteModal = ({
  setIsModalOpen,
  isModalOpen,
  formValues,
  formData,
}: modalProps) => {
  const [form] = Form.useForm();
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(false);
  const [isThankYouModalOpen, setThankYouModalOpen] = useState<boolean>(false);
  const annualRevenue: object[] = [
    { value: "Less than $100,000", label: "Less than $100,000" },
    { value: "$100,000 - $500,000", label: "$100,000 - $500,000" },
    { value: "$500,000 - $1 million", label: "$500,000 - $1 million" },
    { value: "More than $1 million", label: "More than $1 million" },
  ];
  const shipment: object[] = [
    { value: "Less than 10 shipments", label: "Less than 10 shipments" },
    { value: "10 - 50 shipments", label: "10 - 50 shipments" },
    { value: "50 - 100 shipments", label: "50 - 100 shipments" },
    { value: "More than 100 shipments", label: "More than 100 shipments" },
  ];
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };
  const onFinish = async (values: any) => {
    setSaveLoader(true);
    let response;
    try {
      response = await axios.post("/api/ERP/move/move_request_quote", {
        headers: { sessionId: getCookie("sessionid") },
        data: {
          origin: formValues?.Origin,
          destination: formValues?.Destination,
          length: formValues?.Length,
          width: formValues?.Width,
          height: formValues?.Height,
          weight: formValues?.Weight,
          no_of_pallets: formValues?.No_of_Pallets,
          business_email: values?.email,
          first_name: values?.first_name,
          last_name: values?.last_name,
          company_name: values?.company_name,
          phone_number: values?.phone_number,
          select_annual_revenue_range: values?.annual_revenue,
          number_of_freight_shipments_per_month: values?.freight_shipments,
        },
      });
      setIsModalOpen(false);
      setSaveLoader(false);
      form.resetFields();
      formData.resetFields();
      setThankYouModalOpen(true);
    } catch (error: any) {
      setSaveLoader(false);
      if (error.response && error.response.status === 429) {
          notification.error({
            message: 'Too Many Requests',
            description: 'You have made too many requests. Please try again later.',
            duration: 4,
          });
      } else {
          notification.error({
            message: `${
              error["response"]?.data?.detail ||
              getText().General.Messages.SomethingWrongTryAgain
            }`,
            duration: 4,
          });
        }
    }
  };
  return (
    <Fragment>
      {isModalOpen && (
        <Modal
          className={style.haggleModal}
          centered
          open={isModalOpen}
          footer={null}
          onCancel={() => setIsModalOpen(false)}
        >
          <h2 className="font-bolder-h2">
            {getText()?.requestQuote?.requestQuoteTitle}
          </h2>
          <p className="mb-18">
            {getText()?.requestQuote?.requestQuoteSubTitle}
          </p>
          <Form
            className={style.haggleForm}
            form={form}
            onFinish={onFinish}
            onFieldsChange={handleFormChange}
          >
            <Row>
              <Form.Item
                validateTrigger="onBlur"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input a valid email.",
                  },
                ]}
              >
                <Field placeholder="Business Email" label="Email" />
              </Form.Item>
            </Row>
            <Row gutter={[16, 12]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  validateTrigger="onBlur"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field placeholder="First Name" label="First Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  validateTrigger="onBlur"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field placeholder="Last Name" label="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                name="company_name"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: "This field should not be empty" },
                ]}
              >
                <Field placeholder="Company Name" label="Company Name" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                validateTrigger="onBlur"
                name="phone_number"
                rules={[
                  () => ({
                    validator: (_, value) =>
                    phoneValidator(_, value, "phone_number"),
                  }),
                ]}
                normalize={normalizePhoneNumber}
              >
                <Field placeholder="Phone number" label="Phone number" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                name="annual_revenue"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: "This field should not be empty" },
                ]}
              >
                <Select
                  placeholder="Select annual revenue range"
                  options={annualRevenue}
                />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                name="freight_shipments"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: "This field should not be empty" },
                ]}
              >
                <Select
                  placeholder="Number of Freight shipments per month"
                  options={shipment}
                />
              </Form.Item>
            </Row>
          </Form>
          <div className={`${style.submitButton} w-100`}>
            <CustomButton
              theme="primary"
              onClick={() => {
                form?.submit();
              }}
              className="py-7 h-auto"
              disabled={disabledSave}
              loading={saveLoader}
            >
              {getText()?.requestQuote?.submit}
            </CustomButton>
          </div>
        </Modal>
      )}

      <ThankYouModal
        isThankYouModalOpen={isThankYouModalOpen}
        setThankYouModalOpen={setThankYouModalOpen}
      />
    </Fragment>
  );
};

export default RequestQuoteModal;
